// src/templates/pages/allWpPodcast.js

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/Layout.js'
import ResourceListingLayout from '../../components/ResourceListingLayout.js'

function PodcastListing(props) {
  const resourcesData = props.data.allWpPodcast

  return (
    <Layout>
      <ResourceListingLayout
        resourceType={props.pageContext.resourceType}
        resourceFilters={props.pageContext.resourceFilters}
        tagsList={props.pageContext.tagsList}
        resourcesList={resourcesData.edges}
        currentPage={props.pageContext.currentPage}
        numPages={props.pageContext.numPages}
        pageSlug={`resources/${props.pageContext.resourceType}`}
      />
    </Layout>
  )
}

export default PodcastListing

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    allWpPodcast(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PodcastResourceFragment
        }
      }
    }
  }
`
